<template>
  <v-container fluid>
    <v-dialog v-model="dialog" persistent max-width="900px">
      <v-card class="dialog-top-color" v-if="!$vuetify.breakpoint.xs">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-spacer />
          <v-toolbar-title>
          </v-toolbar-title>
          <v-spacer />
        </v-app-bar>
        <v-card-text>
          <v-row :align="'center'" :justify="'center'" class="mb-5">
            <v-col :align="'center'" :justify="'center'" cols="3">
              <v-img :src="require('@/assets/logo/logo_complete_b.svg')" max-width="120" max-height="150"></v-img>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col :align="'center'" :justify="'center'" cols="3" class="ml-3">
              <v-img :src="require('@/assets/logo/young_logo_b.svg')" max-width="350" max-height="180"></v-img>
            </v-col>
          </v-row>
          <v-row :align="'center'" :justify="'center'">
            <v-col :align="'center'" :justify="'center'">
              <h1 class="accent--text mb-2">{{ $t('young.youngBannerTitleRow1') }}</h1>
              <h1 class="accent--text">{{ $t('young.youngBannerTitleRow2')  }}</h1>
            </v-col>
          </v-row>
          <v-row :align="'center'" :justify="'center'">
            <v-col :align="'center'" :justify="'center'">
              <h2>{{ $t('young.youngBannerDescriptionRow1') }}</h2>
              <h2 >{{ $t('young.youngBannerDescriptionRow2') }}</h2>
              <h2 class="my-3"><span style="color: #00D672; font-size: xx-large; ">{{getYoungPricing}}€</span></h2>
              <h2>{{ $t('young.youngBannerDescriptionRow3') }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature1Title') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature1Description') }}
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>

            <v-col>
              <v-card>
                <v-card-title>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature2Title') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature2Description') }}
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature3Title') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature3Description') }}
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="accent" class="white--text ma-3" tile :loading="loading" @click="$router.replace('/add-connection')">
            {{ $t('young.youngButton1') }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-card v-else class="dialog-top-color">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-spacer />
          <v-toolbar-title>
          </v-toolbar-title>
          <v-spacer />
        </v-app-bar>
        <v-card-text>
          <v-row :align="'center'" :justify="'center'" class="mb-5">
            <v-col :align="'center'" :justify="'center'" cols="8">
              <v-img :src="require('@/assets/logo/logo_complete_b.svg')" max-width="120" max-height="150"></v-img>
            </v-col>
            <v-col :align="'center'" :justify="'center'" cols="8">
              <v-img :src="require('@/assets/logo/young_logo_b.svg')" max-width="250" max-height="150"></v-img>
            </v-col>
          </v-row>
          <v-divider class="mb-5"></v-divider>
          <v-row :align="'center'" :justify="'center'">
            <v-col :align="'center'" :justify="'center'">
              <h1 class="accent--text mb-2">{{ $t('young.youngBannerTitleRow1') }}</h1>
              <h1 class="accent--text">{{ $t('young.youngBannerTitleRow2')  }}</h1>
            </v-col>
          </v-row>
          <v-row :align="'center'" :justify="'center'">
            <v-col :align="'center'" :justify="'center'">
              <h2>{{ $t('young.youngBannerDescriptionRow1') }}</h2>
              <h2 >{{ $t('young.youngBannerDescriptionRow2') }}</h2>
              <h2 class="my-3"><span style="color: #00D672; font-size: xx-large; ">{{getYoungPricing}}€</span></h2>
              <h2>{{ $t('young.youngBannerDescriptionRow3') }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature1Title') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature1Description') }}
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature2Title') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature2Description') }}
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature3Title') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <v-row :align="'center'" :justify="'center'">
                    <v-col :align="'center'" :justify="'center'">
                      {{ $t('young.youngFeature3Description') }}
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row :align="'center'" :justify="'center'">
            <v-col :align="'center'" :justify="'center'">
              <v-btn color="accent" class="white--text" tile :loading="loading" @click="$router.replace('/add-connection')">
            {{ $t('young.youngButton1') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* global fbq */
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import moment from "moment";
export default {
  name: 'home-page',
  data() {
    return {
      dialog: false,
      loading: false,
      pricing: {
        'BASE': 59,
        'BRONZE': 54,
        'SILVER': 49,
        'GOLD': 44,
        'PLATINUM': 39,
      }
    }
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("userAddresses", ["addresses"]),
    getYoungPricing(){
      return this.pricing[this.loggedUser.externalTier]
    }
  },
  methods: {
    ...mapActions("userAddresses", ["fetchUserAddresses"]),

  },
  mounted() {

  },
  created() {
    this.fetchUserAddresses().then(response => {
      if (response.data.length > 0) {
        this.$router.replace('/overview')
      } else {
        // Check if the user has just registered, if so sent lead event for FB pixel
        if (this.addresses.length == 0 && this.loggedUser.created) {
          // Check if created is between 1 minute from now
          const createdDate = moment(this.loggedUser.created).toDate();
          const now = new Date();
          const diff = Math.abs(now - createdDate);
          const diffSeconds = Math.floor(diff / 1000);
          if (diffSeconds < 10) {
            if (typeof fbq !== "undefined") {
              fbq("track", "Lead");
            }
          }
        }
        if (this.loggedUser.externalTier != null){
          this.$router.replace('/add-connection') // this.dialog = true
        } else {
          this.$router.replace('/add-connection')
        }
      }
    })
  }
}
</script>

<style></style>